import { createRef, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import Container from 'components/common/container';
import { getCurrentVerseNumber, getCurrentChapter, getCurrentBook } from 'store/selectors/bibleTranslations';
import bibleTranslationsSlice from 'store/slices/bibleTranslationsSlice';
import SearchModal from 'components/common/Modals/SearchModal';
import MemosModal from 'components/common/Modals/memosModal';
import { Button, notification } from 'antd';
import { openNotificationWithIcon } from 'config/notification';
import { NotificationType } from 'store/slices/auth/types';
import { getAuthState } from 'store/selectors/auth';
import useChaptersSearch from 'core/hooks/bible/useChaptersSearch';
import { IoSearchSharp, IoBookmarkSharp } from 'react-icons/io5';

import { generateBookmarkId } from '../utils';
import Search from '../Search';
import TranslationsViewerLoader from '../Loaders/TranslationsViewerLoader';
import Note from '../Note';

import useTranslationsVIewer from './useTranslationsViewer';
import Header from './Header';
import VerseActions from './VerseActions';

import '../style.scss';

function TranslationsViewer() {
  const {
    notes,
    bookNumber,
    notesShown,
    bookmarksIds,
    chapterNumber,
    chaptersTexts,
    searchOptions,
    selectedVerses,
    isModalVisible,
    textContainerType,
    searchIsMinimized,
    activeTranslations,
    selectedVerseIndexes,
    chaptersSplittedVerses,
    chaptersCombinedVerses,
    dispatch,
    handleBookmarkClick,
    useMemoSelector,
    isSelectedIndex,
    handleVerseClick,
    setIsModalVisible,
    setSelectedVerses,
    onSearchButtonClick,
    setSearchIsMinimized,
    setSelectedVerseIndexes,
  } = useTranslationsVIewer();

  const translationViewerRef = useRef<HTMLDivElement>(null);
  const currentVerse = useMemoSelector(getCurrentVerseNumber);
  const currentChapter = useMemoSelector(getCurrentChapter);
  const currentBook = useMemoSelector(getCurrentBook);
  const { searchResult, searchForWord } = useChaptersSearch();
  const { userInfo } = useMemoSelector(getAuthState);
  const [memoModalIsOpen, setMemoModalIsOpen] = useState(false);
  const [notificationApi, notificationContextHolder] = notification.useNotification();

  const refs = chaptersSplittedVerses?.[0]?.text.reduce((acc, value, index) => {
    acc[index] = createRef();
    return acc;
  }, [] as RefObject<any>[]);

  const emptySelectedVerses = useCallback(() => {
    setSelectedVerseIndexes([]);
    setSelectedVerses([]);
  }, [setSelectedVerseIndexes, setSelectedVerses]);

  const handleMemosModalClose = (successMessage?: string | null) => {
    setMemoModalIsOpen(false);
    emptySelectedVerses();
    if (successMessage) {
      openNotificationWithIcon({
        api: notificationApi,
        message: 'Message',
        type: NotificationType.success,
        description: successMessage,
        placement: 'top',
      });
    }
  };

  useEffect(() => {
    if (currentVerse < 0) return;

    if (!refs?.[currentVerse]) return;

    refs[currentVerse].current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });

    setTimeout(() => {
      dispatch(bibleTranslationsSlice.actions.setCurrentVerseNumber(-1));
    }, 3000);
  }, [currentVerse, refs, dispatch]);

  const handleMemoClick = useCallback(() => {
    setMemoModalIsOpen(true);
  }, []);

  useEffect(() => {
    if (currentBook?.number || currentChapter || currentVerse) {
      emptySelectedVerses();
      translationViewerRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [currentBook?.number, currentChapter, currentVerse, emptySelectedVerses]);

  return (
    <Container
      fluid
      ref={translationViewerRef}
      style={{
        height: 'calc(100vh - 110px)',
        overflowY: 'auto',
      }}
    >
      {chaptersTexts?.length && chaptersSplittedVerses ? (
        <div className={`${textContainerType} translation-viewer`}>
          {userInfo?._id && selectedVerseIndexes.length > 0 && (
            <VerseActions handleMemoClick={handleMemoClick} />
          )}
          {searchIsMinimized && searchResult && (
            <Button
              className="translation-viewer__search-button"
              type="primary"
              onClick={() => setIsModalVisible(true)}
            >
              <IoSearchSharp fontSize={30} />
            </Button>
          )}
          {notificationContextHolder}
          <table className="translation-viewer__table">
            <Header
              notesShown={notesShown}
              chaptersTexts={chaptersTexts}
              activeTranslations={activeTranslations}
              onSearchButtonClick={onSearchButtonClick}
            />
            <tbody>
              {chaptersCombinedVerses?.map((versesInTranslation, rowIndex) => (
                <tr key={versesInTranslation.join(',') + rowIndex.toString()} ref={refs[rowIndex]}>
                  {versesInTranslation.some((verse => verse.text.trim())) && versesInTranslation.map((verse, columnIndex) => (
                    <td
                      key={verse.text}
                      className={`translation-viewer__verse-title-container 
                        ${chaptersTexts.length > 1 ? 'translation-viewer__verse-title-container__bordered' : ''}
                        ${rowIndex === currentVerse ? 'translation-viewer__verse-title-container__bordered__active' : ''}
                        ${userInfo?._id && isSelectedIndex(rowIndex, columnIndex) !== -1 ? 'translation-viewer__verse-title-container__bordered__active' : ''}
                        ${userInfo?._id && notes?.[rowIndex] && !notesShown ? 'translation-viewer__verse-with-note' : ''}
                      `}
                      onClick={e => handleVerseClick(rowIndex, columnIndex, verse, e)}
                    >
                      {userInfo?._id && isSelectedIndex(rowIndex, columnIndex) !== -1 && (
                        <IoBookmarkSharp
                          className={`
                            translation-viewer__add-bookmark-button
                            ${bookmarksIds.includes(generateBookmarkId({ bookNumber, chapterNumber, verseIndex: rowIndex, translationId: verse.translation_id }))
                              && 'translation-viewer__add-bookmark-button__active'}
                          `}
                          onClick={() => handleBookmarkClick(rowIndex, verse)}
                        />
                      )}
                      {verse.text}
                    </td>
                  ))}
                  {userInfo?._id && notesShown && (
                    <td className="notes-cell">
                      <Note
                        key={`${chapterNumber} - ${rowIndex.toString()}`}
                        bookNumber={bookNumber}
                        chapterNumber={chapterNumber}
                        verseIndex={rowIndex}
                        note={notes?.[rowIndex]}
                      />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          {searchOptions?.id && searchOptions?.modalTitle && (
            <SearchModal
              title={searchOptions?.modalTitle}
              open={isModalVisible}
              onMinimize={() => {
                setIsModalVisible(false);
                setSearchIsMinimized(true);
              }}
              onCancel={() => {
                setIsModalVisible(false);
                searchForWord({});
              }}
            >
              <Search
                id={searchOptions?.id}
                searchForWord={searchForWord}
                searchResult={searchResult || {}}
                emptySelectedVerses={emptySelectedVerses}
                setSearchIsMinimized={setSearchIsMinimized}
                closeModal={() => setIsModalVisible(false)}
              />
            </SearchModal>
          )}
          {memoModalIsOpen && (
            <MemosModal
              isOpen={memoModalIsOpen}
              selectedVerses={selectedVerses}
              onClose={handleMemosModalClose}
            />
          )}
        </div>
      ) : (
        <TranslationsViewerLoader />
      )}
    </Container>
  );
}

export default TranslationsViewer;
