export enum NotificationType {
  info= 'info',
  warning= 'warning',
  error= 'error',
  success= 'success',
}

export interface IAuthMessage {
  message: string;
  type: NotificationType;
  key: string;
}

export interface IUserInfo {
  full_name: string;
  username: string;
  _id?: string;
}

export interface IAuth {
  loading: boolean;
  userInfo?: IUserInfo;
  message?: IAuthMessage;
}

export interface IRegisterParams {
  full_name: string;
  username: string;
  password: string;
}

export type ILoginParams = Omit<IRegisterParams, 'full_name'>;

export interface ILoginResponse {
  message: IAuthMessage;
  user: IUserInfo;
}
