import { BrowserRouter, useRoutes } from 'react-router-dom';
import Bible from 'components/pages/bible';
import HomePage from 'components/pages/home';
import Learning from 'components/pages/learning';
import ChurchActs from 'components/pages/churchActs';
import GodsCalling from 'components/pages/godsCalling';
import ManDirect from 'components/pages/manDirect';
import Audios from 'components/pages/audios';
import AncestorsProverbs from 'components/pages/ancestorsProverbs';
import Preaches from 'components/pages/preaches';
import Login from 'components/pages/auth/login';
import Signup from 'components/pages/auth/signup';
import ResetPassword from 'components/pages/auth/resetPassword';
import ContactUs from 'components/pages/contactus';
import Memos from 'components/pages/memos';
import Programs from 'components/pages/programs';
import Stream from 'components/pages/stream';

const ROUTES = [
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/signup',
    element: <Signup />,
  },
  {
    path: '/learning',
    element: <Learning />,
  },
  {
    path: '/lessons',
    element: <Learning />,
  },
  {
    path: '/videos',
    element: <Preaches />,
  },
  {
    path: '/programs',
    element: <Programs />,
  },
  {
    path: '/audio',
    element: <Audios />,
  },
  {
    path: '/bible',
    element: <Bible />,
  },
  {
    path: '/church-acts',
    element: <ChurchActs />,
  },
  {
    path: '/memos',
    element: <Memos />,
  },
  {
    path: '/gods-calling',
    element: <GodsCalling />,
  },
  {
    path: '/man-direct',
    element: <ManDirect />,
  },
  {
    path: '/ancestors-proverbs',
    element: <AncestorsProverbs />,
  },
  {
    path: '/contact-us',
    element: <ContactUs />,
  },
  {
    path: '/stream',
    element: <Stream />,
  },
  {
    // The default route with the asterisk has to be last in the current hierarchy level to work
    path: '*', // go here if URL is not from list above
    element: <HomePage />,
  },
];

export function Router() {
  return useRoutes([
    ...ROUTES,
  ]);
}

export const RouterProvider = BrowserRouter;
