import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBibleTranslations, useChapterText, useBooks } from 'core/hooks';
import { useAppDispatch, useMemoSelector } from 'store/hooks';
import { getAuthState } from 'store/selectors/auth';
import { getBookmarks as getBookmarksSelector } from 'store/selectors/bookmarks';
import { createBookmark, getBookmarks, removeBookmark } from 'store/slices/bookmarks/actions';
import { getCertainNotes } from 'store/slices/notes/actions';
import { useSelector } from 'react-redux';
import { getBibleTranslationsIds } from 'store/selectors/bibleTranslations';
import { getNotes } from 'store/selectors/notes';
import { INote } from 'types';

import { generateBookmarkId } from '../utils';

interface ISearchOptions {
  id: number | null;
  modalTitle: string | null;
}

interface ISelectedVerses {
  text: string;
  translationId: number;
  shortTitle: string;
  chapterNumber: number;
  verseNumber: number;
  location: number[];
}

const useTranslationsVIewer = () => {
  const dispatch = useAppDispatch();
  const { getBookByNumber } = useBooks();
  const { userInfo } = useSelector(getAuthState);
  const bookmarks = useMemoSelector(getBookmarksSelector);
  const selectedTranslations = useMemoSelector(getBibleTranslationsIds);
  const notes = useMemoSelector(getNotes);
  const { chaptersTexts, chaptersSplittedVerses, currentBook, chapterNumber } = useChapterText();
  const { bibleTranslations } = useBibleTranslations();
  const [selectedVerseIndexes, setSelectedVerseIndexes] = useState<number[][]>([]);
  const [selectedVerses, setSelectedVerses] = useState<ISelectedVerses[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [searchOptions, setSearchOptions] = useState<ISearchOptions>();
  const [searchIsMinimized, setSearchIsMinimized] = useState<boolean>(false);

  const notesReducedByVerseIndex = useMemo(() => (
    notes.reduce((acc, note) => {
      acc[note.verseIndex] = note;
      return acc;
    }, {} as Record<number, INote>)
  ), [notes]);

  const chaptersCombinedVerses = useMemo(() => (
    chaptersSplittedVerses?.[0]?.text.reduce((result, _, index) => {
      // eslint-disable-next-line no-param-reassign
      result[index] = [];
      chaptersSplittedVerses.forEach(translationVerse => {
        result[index].push({
          text: translationVerse.text?.[index],
          translation_id: translationVerse.translationId,
        });
      });
      return result;
    }, [] as { text: string; translation_id: number }[][])
  ), [chaptersSplittedVerses]);

  const textContainerType = useMemo(() => {
    const translationsCount = chaptersTexts.length;
    if (translationsCount < 3) {
      return 'translation-viewer__small-size';
    }

    if (translationsCount === 3) {
      return 'translation-viewer__middle-size';
    }

    return 'translation-viewer__full-size';
  }, [chaptersTexts]);

  const activeTranslations = useMemo(() => {
    const ids = chaptersTexts.map(({ translation_id }) => translation_id);
    const filteredTranslations = bibleTranslations.filter(t => ids.includes(t.id));
    // sorting translations by ids. It's needed to display translations in the same order as in the bible checked chapters
    const sortedTranslations = filteredTranslations.sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));
    return sortedTranslations;
  }, [bibleTranslations, chaptersTexts]);

  const onSearchButtonClick = useCallback((id: number, name: string, translator_name: string) => {
    setSearchOptions({
      id,
      modalTitle: `${name} ${translator_name}`,
    });
    setSearchIsMinimized(false);
    setIsModalVisible(true);
  }, []);

  const isSelectedIndex = useCallback((rowIndex: number, columnIndex: number) => (
    selectedVerseIndexes.findIndex(item => {
      const [rIndex, cIndex] = item;
      return rowIndex === rIndex && columnIndex === cIndex;
    })
  ), [selectedVerseIndexes]);

  const handleVerseClick = async (
    rowIndex: number,
    columnIndex: number,
    verse: { text: string; translation_id: number },
    e: any,
  ) => {
    if (!userInfo?._id || !e.target.classList.value || !verse.text.trim()) {
      return;
    }

    const alreadyExistsIndex = isSelectedIndex(rowIndex, columnIndex);
    if (alreadyExistsIndex !== -1) {
      const verseIndexes = [...selectedVerseIndexes];
      const selectedVersesCopy = [...selectedVerses];
      verseIndexes.splice(alreadyExistsIndex, 1);
      selectedVersesCopy.splice(alreadyExistsIndex, 1);
      setSelectedVerseIndexes(verseIndexes);
      setSelectedVerses(selectedVersesCopy);
      return false;
    }

    setSelectedVerseIndexes(val => [...val, [rowIndex, columnIndex]]);

    const { text, translation_id } = verse;
    const [bookByNumber] = await getBookByNumber(currentBook.number, translation_id);
    const selectedVerse = {
      text: `${text}`,
      shortTitle: bookByNumber?.short_title,
      chapterNumber,
      verseNumber: rowIndex,
      translationId: translation_id,
      location: [rowIndex, columnIndex],
    };

    setSelectedVerses(val => [...val, selectedVerse]);
  };

  const bookmarksIds = useMemo(() => {
    if (!bookmarks.length) {
      return [];
    }

    return bookmarks?.map(bookmark => (
      generateBookmarkId({
        bookNumber: bookmark.bookNumber,
        chapterNumber: bookmark.chapterNumber,
        verseIndex: bookmark.verseIndex,
        translationId: bookmark.translationId,
      })
    ));
  }, [bookmarks]);

  const handleBookmarkClick = useCallback(async (verseIndex: number, verse: { text: string; translation_id: number }) => {
    const { translation_id: translationId } = verse;
    const { number: bookNumber } = currentBook;
    const [bookByNumber] = await getBookByNumber(bookNumber, translationId);
    const title = bookByNumber?.short_title;

    const currentBookmarkId = generateBookmarkId({ bookNumber, chapterNumber, verseIndex, translationId });
    if (bookmarksIds.includes(currentBookmarkId)) {
      const itemToRemove = bookmarks.find(bookmark => (
        bookmark.bookNumber === bookNumber
        && bookmark.chapterNumber === chapterNumber
        && bookmark.verseIndex === verseIndex
        && bookmark.translationId === translationId
      ));
      if (!itemToRemove) {
        return;
      }

      return dispatch(removeBookmark({ id: itemToRemove?._id }));
    }

    dispatch(createBookmark({
      bookNumber,
      chapterNumber,
      verseIndex,
      translationId,
      title,
    }));
  }, [bookmarks, bookmarksIds, chapterNumber, currentBook, dispatch, getBookByNumber]);

  useEffect(() => {
    if (userInfo?._id) {
      dispatch(getBookmarks());
      dispatch(getCertainNotes({ bookNumber: currentBook.number, chapterNumber }));
    }
  }, [currentBook, chapterNumber, userInfo, dispatch]);

  const notesShown = useMemo(() => selectedTranslations.includes('notes'), [selectedTranslations]);

  return {
    bookmarksIds,
    chapterNumber,
    chaptersTexts,
    searchOptions,
    selectedVerses,
    isModalVisible,
    textContainerType,
    searchIsMinimized,
    activeTranslations,
    selectedVerseIndexes,
    chaptersSplittedVerses,
    chaptersCombinedVerses,
    bookNumber: currentBook.number,
    notes: notesReducedByVerseIndex,
    notesShown,
    dispatch,
    handleBookmarkClick,
    useMemoSelector,
    isSelectedIndex,
    handleVerseClick,
    setSelectedVerses,
    setIsModalVisible,
    onSearchButtonClick,
    setSearchIsMinimized,
    setSelectedVerseIndexes,
  };
};

export default useTranslationsVIewer;
