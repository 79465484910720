import Button from 'antd/es/button';
import useChapterText from 'core/hooks/bible/useChapterText';

interface IVersesNumbersListProps {
  closePopover: () => void;
  onVerseSelect: (verseNumber: number) => void;
}

function VersesNumbersList({ onVerseSelect, closePopover }: IVersesNumbersListProps) {
  const { chaptersSplittedVerses } = useChapterText();

  const handleVerseListItemClick = (verseNumber: number) => {
    closePopover();
    onVerseSelect(verseNumber);
  };

  return (
    <div className="bible-navigation__chapters-container">
      {chaptersSplittedVerses?.[0]?.text?.map((_, index) => (
        <Button
          // eslint-disable-next-line react/no-array-index-key
          key={index.toString()}
          onClick={() => handleVerseListItemClick(index)}
        >
          {index + 1}
        </Button>
      ))}
    </div>
  );
}

export default VersesNumbersList;
