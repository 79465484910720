import moment from 'moment';

import Container from 'components/common/container';
import { Typography } from 'antd';
import { useLanguage, useYoutubeChannel } from 'core/hooks';
import Flex from 'components/common/Flex';

import './style.scss';

enum days {
  wednesday = 'wednesday',
  sunday = 'sunday',
}

const { Title, Paragraph, Link } = Typography;
const CHANNELS = [
  {
    platform: 'Telegram',
    link: 'https://t.me/cbjctv_live',
    name: 'CBJCTV_LIVE',
  },
  {
    platform: 'RuTube',
    link: 'https://rutube.ru/channel/31859343/',
    name: 'CBJC TV',
  },
  {
    platform: 'YouTube',
    link: 'https://www.youtube.com/@cbjctv4484',
    name: 'CBJC TV',
  },
  {
    platform: 'Facebook',
    link: 'https://www.facebook.com/groups/311838435608118',
    name: '«ԿՅԱՆՔ ԵՎ ԽԱՂԱՂՈւԹՅՈւՆ»',
  },
];
function closestDay() {
  const today = moment().day();
  return (today < 3) ? days.wednesday : days.sunday;
}

function Stream() {
  const { currentLanguage } = useLanguage();
  const { channelIsLive, streamTitle } = useYoutubeChannel();
  const DAYS = {
    wednesday: currentLanguage?.stream_wednesday,
    sunday: currentLanguage?.stream_sunday,
  };

  return (
    <Container>
      <div className="stream">
        <Flex
          width="var(--full)"
          direction="column"
          maxWidth={960}
          paddingTop="var(--gap-largest)"
        >
          <Paragraph
            strong
            style={{ fontSize: 'var(--font-main-size)' }}
          >
            {currentLanguage?.stream_info}
          </Paragraph>
          <Paragraph>
            {currentLanguage?.stream_platforms}
          </Paragraph>
          {CHANNELS.map(channel => (
            <Paragraph
              strong
              style={{ marginBottom: 0 }}
            >
              {channel.platform}
              {' '}
              <Link href={channel.link} target="_blank">
                {channel.name}
              </Link>
            </Paragraph>
          ))}
        </Flex>
        {channelIsLive ? (
          <Flex justifyContent="center" marginTop="var(--gap-main)">
            <Title level={5}>{streamTitle}</Title>
            <iframe
              width="960px"
              height="540"
              src="https://www.youtube.com/embed/live_stream?channel=UCphs_rznTG7wrHvWW1YpsFA"
              title="iframe"
              allowFullScreen
            />
          </Flex>
        ) : (
          <div className="stream__info">
            <div className="stream__info__overlap">
              <div className="stream__info__text">
                {DAYS[closestDay()]}
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
}

export default Stream;
