import Navbar from 'components/navbar';
import Sidebar from 'components/sideBar';
import { logInfo } from 'config/logging';
import { useMount } from 'core/hooks';
import { ConfigProvider } from 'antd';

import { Router, RouterProvider } from './router';

import './styles/main.scss';

function App() {
  useMount(() => {
    logInfo('loading application');
  });

  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#722ed1' } }}>
      <RouterProvider>
        <Navbar />
        <div className="app-container">
          <Sidebar />
          <Router />
        </div>
      </RouterProvider>
    </ConfigProvider>
  );
}

export default App;
