import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import YouTube from 'react-youtube';
import { Collapse } from 'antd';
import isArray from 'lodash/isArray';
import { ISnippet } from 'types';
import CopyToClipboard from 'components/common/copyToClipboard';

import Navigation from './Navigation';

import './style.scss';

const { Panel } = Collapse;

interface IPreachesContentProps {
  preachesData: ISnippet[];
  prevPageToken?: string;
  nextPageToken?: string;
  handleNavigation: (type: string) => void;
}

function PreachesContent({ preachesData, ...rest }: IPreachesContentProps) {
  const [pitchVideoPlayerTarget, setPitchVideoPlayerTarget] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (data: string[] | string) => {
    const key = isArray(data) ? data[0] : data;

    key ? searchParams.set('activeKey', key) : searchParams.delete('activeKey');
    setSearchParams(searchParams);

    pitchVideoPlayerTarget?.target?.stopVideo();
    setPitchVideoPlayerTarget(null);
  };

  const defaultActiveKey = searchParams.get('activeKey') || '';

  return (
    <div className="video-content">
      <Collapse
        accordion
        onChange={handleChange}
        defaultActiveKey={defaultActiveKey}
      >
        {preachesData.map(item => (
          <Panel
            header={item.title}
            key={item.resourceId?.videoId}
            extra={(
              <div onClick={e => e.stopPropagation()}>
                <CopyToClipboard
                  text={`${window.location.href}${!defaultActiveKey ? `&activeKey=${item.resourceId.videoId}` : ''}`}
                />
              </div>
            )}
          >
            <YouTube
              className="video-content__player"
              videoId={item.resourceId?.videoId}
              onPlay={(e: any) => setPitchVideoPlayerTarget(e)}
            />
          </Panel>
        ))}
      </Collapse>
      {(rest.prevPageToken || rest.nextPageToken) && (
        <Navigation
          {...rest}
        />
      )}
    </div>
  );
}

export default PreachesContent;
